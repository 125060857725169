<template>
  <div class="side-menu__separator">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.side-menu__separator {
  padding-bottom: 6px;
  padding-top: 14px;
  padding-left: 24px;

  font-family: Nunito Sans;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #959B9F;
}
</style>